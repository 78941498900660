@import '../../../styles/variables.less';

.shareOptionSet {
  .shareOptionSetItem {
    width: 100%;
    // margin-top: 20px;
    .shareOptionTitle {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        list-style: none;
        margin: 5px;
        border: 1px solid #e1e1e1;
        background-color: #fafafa;
        padding: 8px 25px;
        cursor: pointer;
        border-radius: 5px;
        .dropdownBottomItem {
          display: flex;
          .shareOptionLabel {
            font-weight: 500;
            color: #000;
            // margin-left: 8px;
          }
        }
        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }
      }
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;