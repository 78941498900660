@import '../../styles/variables.less';

.searchWithSelect {
  width: 260px;
  .ant-select-selection {
    background: @inputLightColor;
    border: 1px solid @lightthemeBorder;
    border-radius: 10px !important;
    padding: 2px 0;
    height: auto;
    .ant-select-search {
      background: @inputLightColor;
    }
    .ant-select-selection__placeholder {
      color: #000;
    }
    .ant-select-arrow {
      svg {
        fill: @themeColor;
      }
    }
    &:hover {
      border: 1px solid @lightthemeBorder;
    }
  }
  &.ant-select-open .ant-select-selection {
    border: 1px solid @lightthemeBorder !important;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;