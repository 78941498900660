@import '../../styles/variables.less';

.fileUploadedBlock {
  display: flex;
  flex-wrap: wrap;
  .fileUploadedBlockItem {
    width: 180px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin: 5px;
    .fileUplodImg {
      width: 100%;
      height: 80px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &.smallSize {
        height: 50px;
      }
    }
    &.smallSize {
      width: 130px;
      .fileUplodImg {
        height: 50px;
      }
    }
    .fileUplodContent {
      margin-top: 10px;
      p {
        color: #000;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
        word-break: break-word;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deleteIcon {
        position: absolute;
        top: -8px;
        right: -6px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #ddd;
        display: flex;
        cursor: pointer;
        img {
          width: 8px;
          height: 8px;
          margin: auto;
        }
      }
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;