@import '../../styles/variables.less';

.textAreaWrapper {
  margin-bottom: 15px;
}

.textarea {
  border: 1px solid #e4e4e4;
  padding: 10px 0;
  width: 100% !important;
  height: auto !important;
  resize: both !important;

  &:focus {
    outline: none;
  }
}

.commonTextArea {
  border: 1px solid #e4e4e4;
  padding: 10px 0;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.error-message {
  .invalid {
    border-bottom: 1px solid #ee3b3b !important;
  }
  .error {
    color: #ee3b3b;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;