@import '../../styles/variables.less';

.inputBlock {
  margin-bottom: 15px;
  position: relative;
  &.disabled {
    .disabledValue {
      cursor: no-drop;
    }
  }
  label {
    color: #5e5e5e;
    font-size: 12px;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  .inputWrapper {
    position: relative;
    &.inputInselectWrapper {
      .inputInselect {
        border-bottom: 1px solid #e4e4e4;
        padding: 4px 0;
        min-width: 120px;
        .ant-select-selection__rendered {
          margin-left: 0;
        }
      }
    }
    input,
    .disabledValue,
    .defultValueShow {
      border: none;
      display: block;
      color: rgba(34, 34, 34, 0.6);
      padding: 14px 0;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      font-size: 14px;
      line-height: 18px;
      &::placeholder {
        color: rgba(34, 34, 34, 0.6);
        font-size: 14px;
      }
      &.defultValueShow {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:focus {
        border-bottom: 1px solid @themeColor;
      }
    }
    &.plusMinValue {
      input {
        padding-left: 35px;
      }
    }
  }
  .signTag {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid #ddd;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    width: 27px;
    background: #fafafa;
    span {
      font-weight: 600;
    }
  }
  .inputValuePlusMin {
    position: absolute;
    left: 0;
    bottom: 9px;
    border: 1px solid #ddd;
    text-align: center;
    width: 22px;
    background: #fafafa;
    height: 22px;
    line-height: 20px;
    border-radius: 100%;
    span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.plusInputRemove {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 30px;
  margin: auto;
  display: flex;
  .closeInoutItem,
  .plusInputItem {
    width: 25px;
    height: 25px;
    background-color: @themeColor;
    border-radius: 100%;
    display: flex;
    cursor: pointer;
    &.closeInoutItem {
      margin-left: 5px;
    }
  }
  img {
    width: 10px;
    margin: auto;
    height: 10px;
    &.closeInput {
      width: 8px;
      height: 8px;
    }
  }
}
.error-message {
  input {
    border-bottom: 1px solid #ee3b3b !important;
  }
  .error {
    color: #ee3b3b;
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 0;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;