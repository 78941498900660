@import "../../styles/variables.less";

.accordion{
    border: none !important;
    .customcollapselist{
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #fff;
        .accordion__button{
            padding: 0;
            font-weight: 600;
            background-color: transparent;
            font-size: 18px;
            color: #343434;
            display: flex;
            align-items: center;
            &:before{
                color: #bbb;
                width: 8px;
                height: 8px;
                transform: rotate(45deg);
                margin-top: -5px;
            }
            &[aria-expanded='true']::before{
                transform: rotate(224deg);  
                margin-top: 0; 
            }
            &:focus{
                outline: none;
            }
        }
        .accordion__panel{
            padding-bottom: 0;
        }
    }
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;