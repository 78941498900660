@import './variables.less';

.filterTopMainBlock {
  border-bottom: 1px dashed #e1e1e1;
  min-height: 51px;
  padding: 5px 0;
}

.topfiletr {
  padding: 5px;
}

.searchInnerFilterMain {
  display: flex;
  align-items: center;
  padding: 0 10px;

  .searchInnerFilterItem {
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-right: 10px;
    background-color: @themeColor;
    cursor: pointer;

    .searchInnerFilterTitle {
      color: #fff;
      font-size: 12px;
      padding: 4px 0 4px 10px;
    }

    .searchMainFilterIcon {
      margin-left: 5px;

      img {
        width: 12px;
        height: 12px;
        display: block;
      }

      &.filterClose {
        padding: 5px 10px 5px 8px;
        border-left: 1px solid @themeDarkBoreder;

        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.clientDetailDiamonList {
  margin-right: 10px;
  flex-wrap: wrap;

  .listdetailBox {
    margin-left: 10px;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 10px;
      background-color: #a69e9e;
      position: absolute;
      right: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.filterTopOption {
  display: flex;
  padding: 0 10px;
  align-items: center;

  &.SalesPerformance {
    .filterSidebarItem {
      .ant-select {
        width: 100px !important;
      }
    }
  }

  &.SalesPerformancefilter {
    .filterSidebarItem {
      .ant-select {
        width: 180px !important;
        color: white;
      }

      .ant-select-selection__rendered {
        width: 80px !important;
      }

      .ant-select-selection__choice__content {
        color: #fff;
      }
    }
  }

  &.AllUserStatusFilter {
    .filterSidebarItem {
      .ant-select {
        width: 150px !important;
        color: white;
      }

      .ant-select-selection__rendered {
        width: 80px !important;
      }

      .ant-select-selection__choice__content {
        color: #fff;
      }

      .leftMultiSelect {
        .ant-select-selection.ant-select-selection--single {


          .ant-select-selection__rendered {
            padding-left: 2px;
            align-items: center;
            display: flex;
          }
        }
      }
    }
  }

  .dropdownButton {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 8px;
  }

  &.ant-drawer-open {
    .ant-drawer-close {
      left: -65px !important;
    }
  }

  .ant-drawer-close {
    top: 10px;
    left: 0;
    right: auto;
    width: 36px;
    height: 36px;
    padding: 11px;
    background: #fff;
    border: none;
    outline: 0;
    border-radius: 50%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.07);
    stroke: #979797;
    display: flex;
    cursor: pointer;
  }

  .filterSidebarItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
    background: @themeColor;
    border-radius: 25px;
    padding: 0 10px;
    min-height: 30px;

    // &:after{
    //   content: "";
    //   width: 1px;
    //   height: 20px;
    //   background: #ddd;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   margin: auto;
    //   right: 0;
    // }
    // &:last-child{
    //   &:after{
    //     content: none;
    //   }
    // }
    .leftMultiSelect {
      &.headerTop {
        &.ant-select {
          .ant-select-selection {
            justify-content: flex-start;

            .ant-select-selection__rendered {
              width: 100% !important;
            }
          }
        }
      }

      &.headerTopCompany {
        &.ant-select {
          .ant-select-selection {
            justify-content: flex-start;

            .ant-select-selection__rendered {
              width: 195% !important;
            }
          }
        }
      }
    }

    .ant-calendar-picker {

      // background: #c9d0eb;
      // border: none;
      // border-radius: 10px !important;
      .ant-input {
        background-color: transparent;
        padding: 0 !important;
        height: auto;
        border: none;
        width: 230px;
        display: flex;
        align-items: center;

        .ant-calendar-range-picker-separator {
          color: #fff;
        }

        .anticon {
          display: block;
          line-height: 30px;
          padding-left: 7px;
          height: 30px;
          top: 0;
          margin: 0;
          position: relative;
          background: transparent;

          &.anticon-close-circle {
            position: absolute;
            // right: 0;
          }

          &:after {
            content: '';
            width: 1px;
            height: 28px;
            background: @themeDarkBoreder;
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          svg {
            fill: #fff;
          }
        }

        .ant-calendar-range-picker-input {
          width: 45%;
          font-size: 12px;
          color: #fff !important;
        }
      }
    }

    .ant-select {
      height: 20px;
      width: 320px !important;

      .ant-select-selection {
        height: 20px;
        background: transparent;
        min-height: auto;
        padding: 0 3px;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        display: flex;
        justify-content: space-between;

        &:active {
          box-shadow: none;
        }

        .ant-select-selection__rendered {
          line-height: normal;
          margin: 0;
          height: 20px;
          display: flex;

          .ant-select-selection-selected-value {
            color: #fff;
            padding-right: 6px;
          }

          .ant-select-selection__placeholder {
            position: relative;
            top: 0;
            margin-top: 0;
            height: auto;
            color: #fff;
          }

          ul {
            li {
              height: 20px;
              margin-top: 0;
              line-height: 20px;

              &.ant-select-selection__choice {
                margin: 0 3px;
                background: rgba(47, 109, 177, 9%);
                color: #000;
                font-size: 12px;
                height: auto;
                line-height: 18px;
                align-items: center;
                display: flex;
                // &[unselectable="on"]{
                //   display: flex;
                // }
              }
            }
          }
        }

        .ant-select-arrow {
          position: relative;
          top: 0;
          padding-left: 5px;
          right: 0;
          margin: 5px 0;

          &:after {
            content: '';
            width: 1px;
            height: 28px;
            background: @themeDarkBoreder;
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          svg {
            fill: #fff;
          }
        }
      }

      &.updateStausBlock {
        margin-bottom: 0;
        min-width: 80px !important;
        width: auto !important;
        padding: 0;
        border-bottom: none;

        .ant-select {
          padding: 0;
          width: 100% !important;
          border: none !important;
        }

        .ant-select-selection__rendered {
          width: 100%;
        }

        .ant-select-search__field {
          background-color: @themeColor;
          width: 100%;
          color: #fff;
        }

        &.md-sm-size {
          width: 130px !important;
        }

        &.md-size {
          width: 185px !important;
        }

        &.sm-size {
          width: 80px !important;
        }

        &.colunmSettng {
          min-width: 150px !important;
          width: auto !important;
        }
      }
    }
  }

  .filterSidebarItem {
    &.close-button {
      .ant-select .ant-select-selection {
        border-radius: 100%;

        .ant-select-selection__clear {
          border-radius: 100%;
          right: 2px;

          &:hover {
            color: #ffffff;
          }

          svg {
            path {
              stroke: #000;
              vector-effect: non-scaling-stroke;
            }
          }
        }
      }
    }

    &.close-buttonCompny {
      .ant-select .ant-select-selection {
        border-radius: 100%;

        .ant-select-selection__clear {
          border-radius: 100%;
          right: 2px;

          &:hover {
            color: #ffffff;
          }

          svg {
            path {
              stroke: #000;
              vector-effect: non-scaling-stroke;
            }
          }
        }
      }
    }
  }

  &.imageBlockIcon {
    .filterSidebarItem {
      .ant-select {
        .ant-select-selection {
          .ant-select-arrow {
            &:after {
              left: auto;
              right: -6px;
            }
          }
        }
      }
    }
  }

  .filterSidebarTitle {
    font-size: 12px;
    font-weight: 600;
    color: #fff !important;
    margin: 0;
    white-space: nowrap;
  }
}

.filterOption {
  cursor: pointer;

  img {
    width: 14px;
    height: 14px;
    display: block;
  }
}

input.ant-calendar-range-picker-input::placeholder {
  color: #fff;
}

.commonSearchDropdown {
  .ant-select-selection {
    background: @inputLightColor;
    border: 1px solid @lightthemeBorder !important;
    border-radius: 10px !important;
    box-shadow: none !important;

    &:hover {
      border: 1px solid @lightthemeBorder;
    }

    .ant-select-selection__placeholder {
      color: #000;
    }
  }

  &.ant-select-open {
    .ant-select-selection {
      border: 1px solid @lightthemeBorder !important;
      box-shadow: none !important;
    }
  }

  .ant-select-arrow {
    svg {
      fill: @themeColor;
    }
  }
}

.masterWrapper {
  form {
    width: 100%;
  }

  .masterCol {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .from-group {
      width: 33.33%;
      padding-right: 20px;
    }
  }
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;