.mainHeadingTitle{
    font-size: 18px;
    padding: 0 15px;
    font-weight: 600;
}
.popupInnerTitle{
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px 0;
    padding: 0;
    text-decoration: underline;
    line-height: 18px;
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;