@import '../../../styles/variables.less';

.dropdownIconCheckBox {
  margin-right: 5px;
}

.shareOptionSet {
  margin-top: 20px;
  max-height: 332px;
  overflow: auto;

  .shareOptionSetItem {
    width: 100%;

    ul {
      display: flex;
      width: 100%;
      justify-content: flex-start !important;
      align-items: center;
      // border-bottom: 1px solid #e2e2e2;
      // padding-bottom: 10px !important;
      // margin-bottom: 10px !important;

      .shareDropdownUi {
        margin-left: 25px !important;
      }

      .dropdownRightCheck {
        display: flex;
        // width: calc(~'100% - 120px');
        // flex-wrap: wrap;
      }

      li {
        list-style: none;
        margin: 4px;
        border: 1px solid #e1e1e1;
        background-color: #fafafa;
        padding: 8px 10px;
        min-width: 100px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 14px;

        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }

        .dropdownBottomItem {
          display: flex;
          .shareOptionLabel {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

    .dropdownMainTitle {
      width: 120px;
      display: flex;

      span {
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }
    }

    .shareOptionTitle {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      display: block;
    }

    .excelShare {
      border-left: 1px solid #e2e2e2;
      padding-left: 10px;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;