@import "../../styles/variables.less";

.disableBlock{
    position: relative;
    cursor: no-drop;
    &:after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(255,255,255,0.65);
        left: 0;
        right: 0;
        top: 0;
        z-index: 100;
    }
    .kycFormMainBlock{
        height: calc(~"100vh - 105px");
        max-height:calc(~"100vh - 105px");
    }
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;