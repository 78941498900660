@import '../../../src/styles/variables.less';

.staticBoxMain {
  // background-color: @tableBackGroundColor;
  .statiContentBox {
    max-width: 1140px;
    margin: auto;
    padding: 0 0 50px 0;
    .staticContentWrapper {
      padding: 25px;
      background-color: #fff;
      margin-bottom: 20px;
      height: 100%;
    }
    .staticMainTitle {
      font-size: 24px;
      text-transform: capitalize;
    }
  }
}
.careerForm {
  .from-group {
    width: 49%;
  }
  .commonButton {
    margin-top: 15px;
    min-width: 150px;
  }
  .uploadeImageBlock {
    margin-left: 0;
    width: 49%;
    display: flex;
    align-items: center;
    .uploadeBlock,
    input {
      width: 80px;
      height: 80px;
      margin: 0;
      img {
        width: 30px;
        height: 30px;
      }
    }
    input {
      left: 0;
      right: auto;
    }
    .title {
      margin-left: 10px;
    }
  }
}
.detailList {
  color: #000;
  margin-bottom: 20px;
  .staticDetailLabel {
    font-weight: 600;
    margin-right: 10px;
  }
  .statisDetailvalue {
    font-size: 14px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.staticList {
  ul {
    li {
      color: #000;
      line-height: 24px;
      font-size: 15px;
      list-style: none;
      margin-bottom: 15px;
      position: relative;
      padding-left: 10px;
      &::after {
        content: '';
        width: 4px;
        height: 4px;
        background-color: #454545;
        position: absolute;
        left: -5px;
        top: 10px;
        border-radius: 100%;
      }
      a {
        color: #000;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    > ul {
      padding-left: 15px;
      margin-top: 10px;
      p {
        margin-bottom: 10px !important;
      }
    }
    table {
      margin-top: 10px;
      th,
      td {
        text-align: center;
        color: #000;
        padding: 5px;
        border: 1px solid #ddd;
      }
      td {
        font-size: 14px;
      }
    }
  }
  p {
    b {
      font-size: 14px;
      // text-transform: uppercase;
      text-decoration: underline;
      font-weight: 600;
      color: #000;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;